import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Login.css'

const Login = () => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            navigate('/dashboard')
        }
    }, [navigate])

    const loginUser = async (e) => {

        e.preventDefault();

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/login`, {
            username: name,
            password: password
        }, { headers: {'Content-Type': 'application/json'}})
        .then(response => {
            //console.log(response);

            if(response.data.user) {
                localStorage.setItem('token', response.data.user)
                navigate('/dashboard')
            } else if (response.data.status === 'error') {
                toast.error('Väärä salasana tai käyttäjätunnus.')
            } else {
                toast.error('Väärä salasana tai käyttäjätunnus.')
            }

        })


    };

    return (

        <>
        <div className="main-div">
            <h1 className="header-container">{"< >"} IoT {"<"} / {">"}</h1>
            
            <form className="login-form" id="login-form">
                <input
                    className="input"
                    id="username" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Username"
                    autoComplete="username"
                />

                <br />

                <input
                    className="input"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                />

                <br />

                <button className="button" type="submit" value="Login" onClick={(e) => {loginUser(e)}}>Login</button>

            </form>

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        </div>
        </>
    )
}

export default Login;