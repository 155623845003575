import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';

// Components
import LatestMeas from '../components/LatestMeas';
import Navbar from '../components/Navbar';
import LatestMeasv2 from '../components/LatestMeasv2';

// Page styles
import '../Dashboard.css'
import DataGraph from '../components/DataGraph';

function Dashboardv2() {
    const navigate = useNavigate();
 
    const [user, setUser] = useState("");
    const [sensor, setSensor] = useState("");

    const fetchUserData = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/data`, { 
            headers: {
                Authorization: localStorage.getItem('token'),
        }})
        .then((response) => {
            setUser(response.data[0]);
            setSensor(response.data[0].sensors[0]);
        })
    }

    /*
    Cheking wether the user has received a token from back end.
    If there is no token navigate the user back to the login page.
    */
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            const user = jwt(token)
            if (!user) {
                localStorage.removeItem('token')
                navigate('/')
            } else if (Date.now() >= user.exp * 1000) {
              localStorage.removeItem('token')
              navigate('/')
          }
            fetchUserData();
        } else {
            navigate('/')
        }


    }, [navigate])



    /*
      Initial value for the dataset start date and time.
      Upon landing on page, set the initial start date to yesterday.
    */
    const startDate = () => {
      let thisDate = new Date();
  
      const offset = thisDate.getTimezoneOffset();
  
      thisDate.setDate(thisDate.getDate() - 1);
  
      thisDate = new Date(thisDate.getTime() - (offset*60*1000));
  
      //setStartTime(thisDate.toISOString().slice(0, 16))
      return thisDate.toISOString().slice(0, 16);
    }
  
    /*
      Initial value for the dataset end date and time
      Upon landin on page, set the initial end date to now
    */
    const endDate = () => {
      let thisDate = new Date();
  
      const offset = thisDate.getTimezoneOffset();
  
      thisDate = new Date(thisDate.getTime() - (offset*60*1000));
  
      //setEndTime(thisDate.toISOString().slice(0, 16))
      return thisDate.toISOString().slice(0, 16);
    }

  const handleSensorChange = (sensor) => {
    setSensor(sensor);
  }

    return (
      <>

        <Navbar />

        <div className='main'>

            <button onClick={() => console.log(user.sensors)}>Click</button>


            <select value={sensor} onChange={(e) => handleSensorChange(e.target.value)}>
                {user ? user.sensors.map((item,index) => {
                    return(
                        <option key={item} value={item} >{item}</option>
                    )
                }) : <option></option>}
            </select>

            {/* <LatestMeasv2 props={{sensor}} /> */}

            <DataGraph props={{sensor: sensor, start: startDate(), end: endDate()}}/>

        </div>
  
      </>
    );
  }
  
  export default Dashboardv2;