import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HomeLogo from "../icons/icons8-home.svg";
import UserLogo from "../icons/icons8-user-30.png";
import SettingsLogo from "../icons/icons8-settings.svg";
import LogoutLogo from "../icons/icons8-logout-50.png";

import "./Navbar.css"

const Navbar = () => {
    const [dateState, setDateState] = useState(new Date());

    useEffect(() => {
        setInterval(() => {
            setDateState(new Date())
        }, 30000);
    })

    const navigate = useNavigate();

    return (
        <div className='navbar'>
          

        <div style={{display: 'flex'}}>
            
        <button
            name="home"
            onClick={ () => {navigate('/dashboard')} }>
            <img src={HomeLogo} alt="Home Logo" className="icon-style"  style={{ height: 30, width: 30 }}/>
            {" "}Koti
        </button>

        <button 
            name="account"
            onClick={ () => {navigate('/account')} }>
            <img src={UserLogo} alt="User Logo"/>
            {" "}Käyttäjä
        </button>

        <button 
            name="settings"
            onClick={ () => {navigate('/settings')}}>
            <img src={SettingsLogo} alt="Settings Logo"  style={{ height: 30, width: 30 }}/>
            {" "}Asetukset
        </button>

        <button 
            name="logout"
            onClick={() => {
                localStorage.removeItem('token')
                navigate('/')
            }}>
            <img src={LogoutLogo} alt="Logout Logo"  style={{ height: 30, width: 27 }}/>
            {" "}Uloskirjatuminen
        </button>
        </div>

        <div className="time-div">
            <p className="date">{dateState.toLocaleDateString('fi-FI', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })}</p>

            <p className="time">{dateState.toLocaleString('fi-FI', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
            })}</p>
        </div>

      </div>
    )
}

export default Navbar;