import React, { useEffect } from 'react';
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Components
import LatestMeas from './components/LatestMeas';
import InTemperatureGraph from "./components/InTemperatureGraph";
import InHumidityGraph from "./components/InHumidityGraph";
import OutTemperatureGraph from "./components/OutTemperatureGraph";
import Navbar from './components/Navbar';

// Page styles
import './Dashboard.css'

function Dashboard() {
    const navigate = useNavigate();


    /*
    Cheking wether the user has received a token from back end.
    If there is no token navigate the user back to the login page.
    */
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            const user = jwt(token)
            //console.log(user);
            if (!user) {
                localStorage.removeItem('token')
                navigate('/')
            } else if (Date.now() >= user.exp * 1000) {
              localStorage.removeItem('token')
              navigate('/')
              toast.warn("Istunto vanhentunut")
          }
        } else {
            navigate('/')
        }


    }, [navigate])

    /*
      Initial value for the dataset start date and time.
      Upon landing on page, set the initial start date to yesterday.
    */
    const startDate = () => {
      let thisDate = new Date();

      const offset = thisDate.getTimezoneOffset();

      thisDate.setDate(thisDate.getDate() - 1);

      thisDate = new Date(thisDate.getTime() - (offset*60*1000));

      //setStartTime(thisDate.toISOString().slice(0, 16))
      return thisDate.toISOString().slice(0, 16);
  }

  /*
    Initial value for the dataset end date and time
    Upon landin on page, set the initial end date to now
  */
  const endDate = () => {
      let thisDate = new Date();

      const offset = thisDate.getTimezoneOffset();

      thisDate = new Date(thisDate.getTime() - (offset*60*1000));

      //setEndTime(thisDate.toISOString().slice(0, 16))
      return thisDate.toISOString().slice(0, 16);
  }

    return (
      <>

        <Navbar />

        <div className='main'>

          <div className='info-container'>
            <LatestMeas />
          </div>

          <hr />
  
          <div className='chart-container'>
            <InTemperatureGraph props={ {start: startDate(), end: endDate()} }/>
          </div>
  
          <div className='chart-container'>
            <InHumidityGraph props={ {start: startDate(), end: endDate()} }/>
          </div>
  
          <div className='chart-container'>
            <OutTemperatureGraph props={ {start: startDate(), end: endDate()} }/>
          </div>

        </div>

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
  
      </>
    );
  }
  
  export default Dashboard;