import React, { useState, useEffect } from "react";
import axios from "axios";

import 'chartjs-adapter-moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import moment from 'moment';
  import "moment/locale/fi";

  moment.locale('fi-FI');

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

export let options = {
    responsive: true,
    scales: {
        x: {
            type: 'time',
            time: {
                tooltipFormat: 'D MMM, H:mm:ss',
                displayFormats: {
                    'millisecond':'D MMM, H:mm',
                    'second': 'D MMM, H:mm',
                    'minute': 'D MMM, H:mm',
                    'hour': 'D MMM, H:mm',
                    'day': 'D MMM, H:mm',
                    'week': 'D MMM, H:mm',
                    'month': 'D MMM, H:mm',
                    'quarter': 'D MMM, H:mm',
                    'year': 'D MMM, H:mm',
                },
                
            }
        }, 
    },
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
  };

/* const cfg = {
      datasets: [{
        data: [{x: '2016-12-25', y: 20}, {x: '2016-12-26', y: 10}]
      }],
} */

const DataGraph = ({ props }) => {

    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [chartName, setChartName] = useState([]);
    const [start, setStart] = useState(props.start);
    const [end, setEnd] = useState(props.end);

    let sensor = props.sensor;

    let keys = [];

    const getDataBySensor = () => {

        if (sensor) {
        //axios.get('/sensor/api/getdata/' + sensor + '-' + start + '-' + end).then(response => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/sensor/api/getdata/`, { params: {id: sensor, start: start, end: end}}).then(response => {

            try {
            let temp = response.data[0].data
            //console.log(temp);



            keys = Object.keys(temp[0]).filter((key) => key !== 'time');
            setChartName(Object.keys(temp[0]).filter((key) => key !== 'time'));

            let timeKey = 0;
            let tempKeys = Object.keys(temp[0]);
            for(let i = 0; i < tempKeys.length; i++) {
                if (tempKeys[i] === 'time') {
                    break;
                } else {
                    timeKey = timeKey + 1;
                }
            }

            let data = []
            let index = 0;
            for(let i = 0; i < tempKeys.length; i++) {
                    if (i === timeKey) {
                        continue;
                    } else {
                    data[index] = temp.map(item => {
                        return({
                            x: item.time,
                            y: Object.values(item)[i]
                        })
                    })
                    index = index + 1;
                }
            }
            //console.log(data);

            let datasets_temp = [];
            for (let i = 0; i < data.length; i++) {
                datasets_temp.push({
                    label: keys[i],
                    data: data[i],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                })
            }

            //console.log(keys);

            setData({
                datasets: datasets_temp,
            });
            } catch (error) {
                setData([]);
                console.log("Ei dataa");
            }
        }
        

        )
    } else {
        console.log("Ei sensoria")
    }

        //console.log(data)
    }

    const mapData = () => {
        console.log(data.datasets);

        let mappedData = data.datasets.map(item => {
            return ( {datasets:[item]} );
        });

        console.log(mappedData);
    }

    useEffect(() => {
        getDataBySensor();
    }, [sensor])

    return (
        <div>
            <h3>{ sensor }</h3>
            <button onClick={() => getDataBySensor()}>Click</button>

            {/* data.length < 1 ? <span>Ei dataa</span>: <Line options={options} data={data} /> */} 

            <button onClick={() => mapData()}>Map data</button>

            <div className='date-container'>
                <div className='start-div'>
                    <label htmlFor="start-time" className='date-label'>Alkuaika</label>
                    <input type='datetime-local' name='start-time' className='start-time' value={start} onChange={(e) => setStart(e.target.value)}/>
                </div>

                <div className='start-div'>
                    <label htmlFor="end-time" className='date-label'>Loppuaika</label>
                    <input type='datetime-local' name='end-time' className='start-time' value={end} onChange={(e) => setEnd(e.target.value)}/>
                </div>

                <button onClick={() => getDataBySensor()} className='date-button'>Hae</button>
            </div>

            { data.length < 1 ? <span>Ei dataa</span> : data.datasets.map((item, index) =>{
                
                return (
                    options.plugins.title.text = chartName[0],
                    <Line key={index} options={options} data={{datasets: [item]}} />
                    )
            })}

        </div>
    )
}

export default DataGraph;