import React, { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'

import "./Account.css"

import Navbar from "../components/Navbar";

const Account = () => {
    const [name, setName] = useState("");
    const [keys, setKeys] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            const user = jwtDecode(token)
            //console.log(user);
            setName(user.name);
            if (!user) {
                localStorage.removeItem('token')
                navigate('/')
            } else if (Date.now() >= user.exp * 1000) {
                localStorage.removeItem('token')
                navigate('/')
                toast.warn("Istunto vanhentunut")
            }
        } else {
            navigate('/')
        }


    }, [navigate])

    const fetchUserKeys = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/fetch-keys`, { 
            headers: {
                Authorization: localStorage.getItem('token'),
        }})
        .then(response => {
            //console.log(response.data.apikeys);
            setKeys(response.data.apikeys);
        })
    }

    const createKey = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/create-key`, { 
            headers: {
                Authorization: localStorage.getItem('token'),
        }})
        .then(() => {
            fetchUserKeys();
        })
    }

    const handleRemove = (item, index) => {
        //console.log('Poistettava ' + item + ' indeksillä ' + index);

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/user/delete-key`, {
            headers: {
                Authorization: localStorage.getItem('token')
            },
            data: {
                source: item,
            }
        })
        .then(() => {
            fetchUserKeys();
        });

    }

    useEffect(() => {fetchUserKeys()}, [])

    return (
        <div className="main"> 
            <Navbar />

            <h3>Käyttäjänimi: {name}</h3>

            <p>Luo uusi api-avain: <button className="button-v2" onClick={() => {createKey()}}>Luo</button></p>
            

            <h3>Käyttäjän api-avaimet:</h3>

            <ol>
            {keys===undefined ? <li>Käyttäjällä ei ole api-avaimia</li> : keys.map((item, index) => {
               return (<div key={index + 'b'} className="account-keys">
                    <li key={index}>{item}</li>
                    <button className="button-v2" onClick={() => handleRemove(item, index)}>Poista</button>
                </div>
                )})}
            </ol>

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>

        </div>
    )
}

export default Account;