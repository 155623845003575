import React, { useEffect, useState } from "react";
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Navbar from "../components/Navbar";
import AdminTools from "../components/AdminTools";

function Settings() {
    const [name, setName] = useState("");
    const [oldpw, setOldpw] = useState("");
    const [newpw1, setNewpw1] = useState("");
    const [newpw2, setNewpw2] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            const user = jwt(token)
            //console.log(user);
            setName(user.name);
            if (!user) {
                localStorage.removeItem('token')
                navigate('/')
            } else if (Date.now() >= user.exp * 1000) {
                localStorage.removeItem('token')
                navigate('/')
                toast.warn("Istunto vanhentunut")
            }
        } else {
            navigate('/')
        }


    }, [navigate])

    const handleChange = () => {

        if(newpw1.length < 12) {
            //alert("Salasanan tulee olla vähintään 12 merkkiä pitkä")
            toast.error("Salasanan tulee olla vähintään 12 merkkiä pitkä")
        } else {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/data`, {
                oldpw: oldpw,
                newpw: newpw1,
            }, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }})
            .then(response => {
                if (response.data.status === 'Password changed') {
                    //alert("Salasanan vaihto onnistui")
                    toast.success("Salasanan vaihto onnistui")
                    setOldpw("");
                    setNewpw1("");
                    setNewpw2("");
                } else if (response.data.status === 'Wrong password') {
                    //alert("Väärä salasana")
                    toast.error("Väärä salasana")
                }
            });
        }


    }

  return (
    <>

        <Navbar />

        <div className="main">

            <h2>Asetukset</h2>

            <div className="password-change">
                <h3>Salasanan vaihto</h3>
                <h4>Kirjautunut käyttäjä: {name}</h4>

                <p>Nykyinen salasana:</p>
                <input className="input" type="password" id="current-password" placeholder="Vanha salasana" value={oldpw} onChange={(e) => {setOldpw(e.target.value)}}></input>

                <p>Uusi salasana:</p>
                <input className="input" type="password" id="new-password-1" placeholder="Uusi salasana" value={newpw1} onChange={(e) => {setNewpw1(e.target.value)}}></input>

                <p>Uusi salasana uudestaan:</p>
                <input className="input" type="password" id="new-password-2" placeholder="Uusi salasana uudestaan" value={newpw2} onChange={(e) => {setNewpw2(e.target.value)}}></input>

                <br></br>

                <button className="button" disabled={newpw1===newpw2 ? false : true} onClick={() => handleChange()}>Tallenna</button>
            </div>

            <div className="admin-tools">
                {jwt(localStorage.getItem('token')).isAdmin ? <AdminTools /> : <p> </p>}
            </div>

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>

        </div>

    </>
  );
}

export default Settings;
