import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./Login";
//import Navbar from "./components/Navbar";
import Dashboard from "./Dashboard";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import Dashboardv2 from "./pages/Dashboardv2";
import DataToBackend from "./pages/proxyDataToBackend";

//import './App.css';

function App() {


  return (
    <div>

      <Router>

        <Routes>

          <Route exact path="/" element={ <Login /> } />

          <Route exact path="/dashboard" element={ <Dashboard /> } />
          <Route exact path="/settings" element={ <Settings /> } />
          <Route exact path="/account" element={ <Account /> } />
          <Route exact path="/home" element={ <Dashboardv2 /> } />
          {/* <Route exact path="/sensor/api/sensor/savedata" element={ <DataToBackend /> } /> */}

        </Routes>

      </Router>

    </div>
  );
}

export default App;
