import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./AdminTools.css"

const AdminTools = () => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [users, setUsers] = useState([]);
    const [userInFocus, setUserInFocus] = useState("");
    const [sensors, setSensors] = useState(undefined);

    const fetchUsers = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/fetch-users`, { 
            headers: {
                Authorization: localStorage.getItem('token'),
        }})
        .then((response) => {
            setUsers(response.data.users);
            if (userInFocus === "") {
                setUserInFocus({
                    name: response.data.users[0].name,
                    sensors: response.data.users[0].sensors,
                });
            }
            //console.log(response.data.users)
        })
    }

    const handleSave = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/register`, {
            name: name,
            password: password,
        })
        .then(response => {
            if(response.data.status === 'ok') {
                toast.success("Käyttäjä " + name + " luotu")
                setName("");
                setPassword("");
                fetchUsers();
            } else {
                toast.error("Käyttäjää " + name + " ei voitu luoda")
            }
        })
    }

    const handleRemove = (item, index) => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/user/delete`, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }, data: {
                source: item.name,
            }
        })
        .then(() => {
            fetchUsers();
            toast.success("Käyttäjä " + item.name + " poistettu")
        })
    }

    const handlePriviliges = (item, index, action) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/priviliges`, {
            name: item.name,
            isAdmin: item.isAdmin,
            human: item.human,
            action: action,
        }, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }
        })
        .then((res) => {
            fetchUsers();
            toast.success("Oikeudet muutettu käyttäjälle " + item.name)
        })
    }

    const handleUserChange = (name) => {

        let temp = users.filter(users => users.name === name)

        setUserInFocus({
            name: temp[0].name,
            sensors: temp[0].sensors,
        });

    }

    const fetchSensors = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/sensor/api/getsensors`, { 
            headers: {
                Authorization: localStorage.getItem('token'),
        }})
        .then (response => {
            //console.log(response.data.sensors)
            setSensors(response.data.sensors)
        })
    }
/* 
    const userHasSensor = (item) => {

        let user = userInFocus;

        if (user.sensors === undefined) {
            return false
        } else if (user.sensors.includes(item)) {
            return true
        } else {
            return false
        }

    }

    const handleDeviceAdd = (name) => {

        const user = userInFocus.name;

        axios.post('/sensor/api/modify/sensorpriviliges', {
            user: user,
            sensorName: name,
            action: "add",
        }, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }
        }).then(response => {
            console.log(response.data)
            fetchUsers()
        })
    }

    const handleDeviceRemove = (name) => {

        const user = userInFocus.name;

        axios.post('/sensor/api/modify/sensorpriviliges', {
            user: user,
            sensorName: name,
            action: "remove",
        }, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }
        }).then(response => {
            console.log(response.data)
        })
    }
 */
    const handleChange = (name, sensorList) => {
        //console.log(name);
        //console.log(sensorList);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/sensor/api/modify/sensorpriviliges`, {
            user: name,
            sensorList: sensorList,
            action: "modify",
        }, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }
        }).then(response => {
            console.log(response.data)
        })
    }

    useEffect(() => {
        fetchUsers()
        fetchSensors()
    }, [])

    return (
        <div className='main-admin'>
            <h2>Hallinta</h2>


            <h3>Käyttäjien hallinta</h3>
            <div className='user-list'>  
                {users===undefined ? <li>Ei käyttäjiä</li> : users.map((item, index) => {
                //return (<li key={index}>{item.name} <input type='checkbox' id={index} onChange={() => handlePriviliges(item, index)} checked={item.isAdmin}/><button onClick={() => handleRemove(item, index)}>Poista</button></li>)
                return (<div key={index + 't'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div><li key={index + 'a'}>{item.name}</li></div>
                    <div><span>Admin</span><input type='checkbox' onChange={() => handlePriviliges(item, index, "priv")} checked={item.isAdmin}/>
                    <span>Human</span><input type='checkbox' onChange={() => handlePriviliges(item, index, "humanormachine")} checked={item.human}/>
                    <button className='button-v2' onClick={() => handleRemove(item, index)}>Poista</button></div>
                </div>)})}
            </div>

            <h3>Uusien käyttäjien luonti</h3>
            <input className='input' type='text' value={name} placeholder='Käyttäjänimi' onChange={(e) => setName(e.target.value)}></input>
            <br />
            <input className='input' type='text' value={password} placeholder='Salasana' onChange={(e) => setPassword(e.target.value)}></input>
            <br />
            <button className='button' onClick={() => handleSave()}>Tallenna</button>

            <hr />
{/* 
            <h3>Valinta</h3>
            <select value={userInFocus.name} onChange={(e) => handleUserChange(e.target.value)}>
                {users.map((item,index) => {
                    return(
                        <option key={item.name} value={item.name} >{item.name}</option>
                    )
                })}
            </select>


            {sensors===undefined ? <li>Ei laitteita</li> : sensors.map((item, index) => {
                return (
                <div key={index + 'div'}>
                    <li key={index}>{item}</li>

                    {userHasSensor(item) ? <button onClick={() => handleDeviceRemove(item)}>Poista</button> : <button onClick={() => handleDeviceAdd(item)}>Lisää</button>}

                </div>
                )
            })}
         */}

            <div className='user-sensor-list'>  
                {sensors===undefined ? <li>Ei laitteita</li> : users.map((item, index) => {
                    return (<div key={index + 'n'} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div><li key={index + 'm'}>{item.name}</li></div>
                        {/*<div>{item.sensors.map((sensor, index) => {return <span key={index}>{sensor},</span>})}</div>*/}
                        <div>
                            <input type='text' id={item.name} defaultValue={item.sensors.map((sensor, index) => {return(sensor)})}  style={{width: "30em"}}></input>
                            <button onClick={() => handleChange(item.name, document.getElementById(item.name).value)}>Tallenna</button>
                        </div>
                </div>)})}

            </div>

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            
        </div>
    )
}

export default AdminTools;