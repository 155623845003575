import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LatestMeas = () => {

    const [inTemp, setInTemp] = useState(0);
    const [inHumi, setInHumi] = useState(0);
    const [outTemp, setOutTemp] = useState(0);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let data;

        data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/find/home/latest`).then(response => {

            return response.data[0];
        })

        setInTemp(data.inTemp);
        setInHumi(data.humi);
        setOutTemp(data.outTemp);
    }

    return (
        <>
            <span>Sisälämpötila {inTemp} C</span>
            <span>Sisätilan suhteellinen kosteus {inHumi} %</span>
            <span>Ulkolämpötila {outTemp} C</span>
        </>
    );
};

export default LatestMeas;