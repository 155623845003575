import React, { useState, useEffect } from 'react';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';
import "moment/locale/fi";

moment.locale('fi-FI');

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

// Text color of the chart component
ChartJS.defaults.color = '#f0f8ff';

/*
    Options for the chartjs component
*/
const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'time',
            time: {
                tooltipFormat: 'D MMM, H:mm:ss',
                displayFormats: {
                    'millisecond':'D MMM, H:mm',
                    'second': 'D MMM, H:mm',
                    'minute': 'D MMM, H:mm',
                    'hour': 'D MMM, H:mm',
                    'day': 'D MMM, H:mm',
                    'week': 'D MMM, H:mm',
                    'month': 'D MMM, H:mm',
                    'quarter': 'D MMM, H:mm',
                    'year': 'D MMM, H:mm',
                },
                
            }
        }, 
    },
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ulkolämpötila',
      },
    },
};

const OutTemperatureGraph =  ({ props }) => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [isDefined, setDefined] = useState(false);
    const [start, setStart] = useState(props.start);
    const [end, setEnd] = useState(props.end);
    const [average, setAverage] = useState([]);

    /*
        Function for setting the datasets to needed format for the chart component.
    */
    const y_data = () => {

        return({
            labels,
            datasets: [
            {
                label: 'Ulkolämpötila',
                data: data,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Keskiarvo',
                data: [{x: labels[0], y: average}, {x: labels.slice(-1)[0], y: average}],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                hidden: true,
            },
        ]}
        );
    
    };

    /*
        Upon loading the page, cheking wether there is data and setting the state isDefined accordingly.
        isDefined state is used to show the user either "Loading..." text or the chart.
        If there is no data then the user sees "Loading..."
    */
    useEffect(() => {
        if ( data.length && labels.length > 0 ) {
            setDefined(true);
        }
    }, [data, labels]);

    /*
        Upon loading the page, making the first data request from the back end.
        Initial request is made with the start and end dates that are initialized on the Dashboard.js component.
        This function will run once.
    */
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/find/home/temperaturedata/v2`, {params: {start: props.start, end: props.end}}).then(response => {
            let x_data1 = response.data.sensor.map(item => {
                let container = {};
                container = new Date(item.time);
                return container;
            });

            let y_data1 = response.data.sensor.map(item => {
                let container = {};
                container = ( item.outTemp );
                return container;
            });

            setLabels(x_data1);
            setData(y_data1);
            setAverage(parseFloat(response.data.average.outTempAve));

        })
    }, [props.start, props.end])

    /*
        Function for requesting data from the backend.
        Start and end dates for the request are entered by the user.
        The function uses "start" and "end" states set by the user.
    */
    const fetchData = () => {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/find/home/temperaturedata/v2`, {params: {start: start, end: end}}).then(response => {
            let x_data1 = response.data.sensor.map(item => {
                let container = {};
                container = new Date(item.time);
                return container;
            });

            let y_data1 = response.data.sensor.map(item => {
                let container = {};
                container = ( item.outTemp );
                return container;
            });

            setLabels(x_data1);
            setData(y_data1);
            setAverage(parseFloat(response.data.average.outTempAve));

        })
    }

    return (

        <>

            <div className='date-container'>
                <div className='start-div'>
                    <label htmlFor="start-time" className='date-label'>Alkuaika</label>
                    <input type='datetime-local' name='start-time' id='start-time' className='start-time' value={start} onChange={(e) => setStart(e.target.value)}/>
                </div>

                <div className='start-div'>
                    <label htmlFor="end-time" className='date-label'>Loppuaika</label>
                    <input type='datetime-local' name='end-time' id='end-time' className='start-time' value={end} onChange={(e) => setEnd(e.target.value)}/>
                </div>

                <button onClick={() => fetchData()} className='date-button'>Hae</button>
            </div>

            <div style={{height: "350px"}}>
                {isDefined ? (
                    <Line options={options} data={y_data()} />
                ) : (
                    <span>Loading....</span>
                )}
            </div>

        </>
    );

  };
    
export default OutTemperatureGraph;